import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import configEs from "../../../content/meta/config.es";
import configEn from "../../../content/meta/config.en";
import { useIntl } from "react-intl";

const languages = require("../../data/languages");

import { isBrowser } from '../../utils/browserUtil';
import { globalHistory } from "@reach/router"


const Seo = props => {
  const { data, facebook } = props;
  console.log(data);
  const postTitle = ((data || {}).frontmatter || {}).title;
  const postDescription = data ? data.frontmatter ? data.frontmatter.seodescription : undefined : undefined;
  const postCover = ((data || {}).frontmatter || {}).cover;
  const postSlug = ((data || {}).fields || {}).slug;
  const currentLang = useIntl();
  const config = currentLang.locale === "es" ? configEs : configEn;

  const title = postTitle ? `${postTitle} | ${config.shortSiteTitle}` : config.siteTitle;
  const description = postDescription ? postDescription : config.siteDescription;
  const image = postCover ? config.siteUrl + (config.pathPrefix ? config.pathPrefix : "") + postCover.childImageSharp.resize.src : config.siteUrl + (config.pathPrefix ? config.pathPrefix : "") + "/" + config.siteImage;
  const pathHistory = globalHistory.location.pathname
  const url = postSlug ? config.siteUrl + postSlug : (typeof window !== "undefined") ? config.siteUrl + pathHistory : pathHistory;

  const path = isBrowser()? pathHistory.replace(`/${currentLang.locale}/`, "").replace(/^[\/]/g, ""): pathHistory;

  return (
    <Helmet
      htmlAttributes={{
        lang: config.siteLanguage,
        prefix: "og: http://ogp.me/ns#"
      }}
    >
      {/* General tags */}
      <title>{title}</title>
      {languages.langs.map((lang, index) => {
        const completeUrl = config.siteUrl + `/${lang}/`.replace(`/${languages.defaultLangKey}/`, "/") + path;
        return(<link key={index} rel="alternate"
                     href={completeUrl}
                     hrefLang={`${lang}-ES`}/>)})}
      {languages.langs.map((lang, index) => {
        const completeUrl = config.siteUrl + `/${lang}/`.replace(`/${languages.defaultLangKey}/`, "/") + path;
        return(<link key={index} rel="alternate"
                     href={completeUrl}
                     hrefLang={`${lang}`}/>)})}
      <meta name="description" content={description}/>
      {/* OpenGraph tags */}
      <meta property="og:url" content={url}/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={image}/>
      <meta property="og:type" content="website"/>
      {/*<meta property="og:image:alt" content={seo.description} />*/}
      <meta property="fb:app_id" content={facebook.appId}/>
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary"/>
      {/*<meta name="twitter:card" content="summary_large_image" />*/}
      {/*<meta name="twitter:title" content={seo.title} />*/}
      {/*<meta name="twitter:url" content={seo.url} />*/}
      {/*<meta name="twitter:description" content={seo.description} />*/}
      {/*<meta name="twitter:image" content={seo.image} />*/}
      {/*<meta name="twitter:image:alt" content={seo.description} />*/}
      {/*<meta name="twitter:creator" content={author} />*/}
      <meta
        name="twitter:creator"
        content={config.authorTwitterAccount ? config.authorTwitterAccount : ""}
      />
    </Helmet>
  );
};

Seo.propTypes = {
  data: PropTypes.object,
  facebook: PropTypes.object.isRequired
};

export default Seo;
